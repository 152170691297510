.paginate-container {
  padding: 15px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.paginate-container button {
  background-color: #ff7a66;
  color: white;
  border: none;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 16px;
}

.paginate-container button:hover {
  filter: brightness(0.9);
}

.paginate-container button:disabled {
  background-color: #ccc;
  color: #666;
}

.paginate-container button:disabled:hover {
  filter: none;
}

.paginate-select {
  display: flex;
  align-items: center;
}

.paginate-select select {
  padding: 4px 8px;
  border: 1px solid #ff7a66;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 4px;
}

.total-pages {
  font-size: 20px;
}

