.pix-payments-container {
}

.pix-payments-header {
  width: 100%;
  display: flex;
  align-items: center;
}

.pix-payments-header button{
  margin-left: 0.5rem;
}

.pix-payments-status {
  display: flex;
  align-items: center;
}

.pix-payments-status p {
  margin-right: 0.5rem;
  margin-bottom: 0;
  font-weight: bold;
}

.pix-payments-noprofessional {
  margin: 0.5rem 0rem;
  color: rgb(117, 117, 117);
}

.pix-payments-table {
  width: 100%;
  border-radius: 5px;
  table-layout:fixed
}


.pix-payments-table input {
  height: 30px;
}

.pix-payments-table button {
  width: 100%;
  height: 30px;
}

.pix-payments-table th {
  font-size: 18px;
}

.pix-payments-table td {
  vertical-align: middle !important;
}

.pix-payments-table td:first-child {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}