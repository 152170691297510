.comment{
    margin: 5px 20px;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.2);
    padding: 15px;
    border-radius: 6px;
    font-weight: normal;
    font-size: 16px;
}
.comments-container, .takeRateHistory-container{
    margin-bottom: 15px;
    background: #fff;
    padding: 48px 60px;
    border-radius: 6px;
    color: #333;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.2);
}
.page-numbers{
    list-style: none;
    display: flex;
    justify-content: center;
}
.page-numbers li{
    padding: 5px 10px;
    cursor: pointer;
}

.currentPage{
    background: #FF7A66;
    color: #fff;
}
