.custom-checkbox {
  --background: #fff;
  --border: #D1D6EE;
  --border-hover: #BBC1E1;
  --border-active: rgb(255,136,102);
  --tick: #fff;
  position: relative;
}

.custom-checkbox input, .custom-checkbox svg {
  width: 21px;
  height: 21px;
  display: block;
}

.custom-checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: var(--background);
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow .3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
}

.custom-checkbox input:hover {
  --s: 2px;
  --b: var(--border-hover);
}

.custom-checkbox input:checked {
  --b: var(--border-active);
}


.custom-checkbox svg {
  pointer-events: none;
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--stroke, var(--border-active));
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  transform: scale(var(--scale, 1)) translateZ(0);
}

.custom-checkbox.checkbox-path input:checked + svg {
  --a: 16.1 86.12;
  --o: 102.22;
}

.custom-checkbox.checkbox-path svg {
  stroke-dasharray: var(--a, 86.12);
  stroke-dashoffset: var(--o, 86.12);
  transition: stroke-dasharray .6s, stroke-dashoffset .6s;
}

.custom-checkbox.checkbox-bounce {
  --stroke: var(--tick);
}

.custom-checkbox.checkbox-bounce input:checked {
  --s: 11px;
}

.custom-checkbox.checkbox-bounce input:checked + svg {
  animation: checkbox-bounce .4s linear forwards .2s;
}

.custom-checkbox.checkbox-bounce svg {
  --scale: 0;
}

@keyframes checkbox-bounce {
  50% {
      transform: scale(1.2);
  }
  75% {
      transform: scale(.9);
  }
  100% {
      transform: scale(1);
  }
}
