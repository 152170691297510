/* styles.css */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  width: 400px;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d1e7dd;
  padding: 1rem;
  z-index: 999999;
  border-radius: 0.4rem;
  animation: fadeIn 0.3s forwards;
}

.fade-out {
  width: 400px;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d1e7dd;
  padding: 1rem;
  z-index: 999999;
  border-radius: 0.4rem;
  animation: fadeOut 0.3s forwards;
}

.fade-in span,
.fade-out span {
  font-family: 'OfeliaDisplay', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #0f5132;
}
