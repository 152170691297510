.ChangeVoucher {
}

.ChangeVoucher-row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.ChangeVoucher-row:not(:first-child) {
  margin: 10px 0 0 0;
}

.ChangeVoucher-title {
  font-size: 16px;
  margin: 0 0 0 15px;
}

.ChangeVoucher-btn:not(:first-child) {
  margin: 0 0 0 20px;
}

.ChangeVoucher-select {
  flex: 1;
}
