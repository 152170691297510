.card-bonus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-bottom: 25px;
  border-radius: 6px;
  width: 30%;
}

.card-item {
  margin: 0 0 4px;
}

.card-item-date {
  margin: 15px 0 4px;
}

.page-bonus {
  display: inline-block;
}

.page-bonus li {
  padding: 5px 10px;
  cursor: pointer;
  display: inline-block;
}

.label-center {
  text-align: center;
}

.card-bonus-button{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-container {
  display: flex; 
  gap: 5px; 
}