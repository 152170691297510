.Top, .box-default {
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.2);
}
.profile-img {
    height: 136px;
    margin-right: 15px;
    margin-bottom: 15px;
}
.top-profile {
    display: flex;

}
.jumbotron .top-title {
    font-size: 35px;
    margin:0;
}
.jumbotron p {
    font-size: 18px;
    margin-bottom: 5px;
}
.service-btn {
    background-color: #d8d5d5;
    border-color: transparent;
    color: black;
    display: inline-block;
    padding: 5px;
    margin: 5px;
    border-radius: 6px;
}
.btns-container {
    position: absolute;
    right: 0;
}
.Top .btn-info {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.gamification-box {
    display: flex;
    justify-content: space-around;
}
.gamification-box div {
    flex-grow: 1;
    text-align: left;
    border-radius: 6px;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.2);
    padding: 5px;
}
.box-default {
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 15px;
}
.box-default h3 {
    margin-top: 0;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}
.ob-reached {
    display: flex;
    align-items: center;
}
h3.ob-reached {
    justify-content: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-top: 0;
    margin-bottom: 15px;
}
.glyphicon-ok {
    color: green;
}
.glyphicon-remove {
    color: red;
}

.infos-importantes {
    margin-top: -15px;
}