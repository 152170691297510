.Partner .react-selectize.root-node {
  width: auto !important;
}

.Partner-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.Partner .row:not(:first-child) {
  margin-top: 10px;
}

.Partner-title {
  font-size: 16px;
}

.Partner-actions {
  margin: 20px 0 0 0;
}

.Partner-detail {
  font-size: 14px;
}

.Partner-btn:not(:first-child) {
  margin: 0 0 0 20px;
}

.ChangePartner-moipFlag {
  font-size: 13px;
  background-color: #CA5E5C;
  color: white;
  padding: 3px 6px 2px 6px;
  float: right;
  border-radius: 4px;
}

.ChangePartner-optionLabel {
  display: inline-block;
  width: 185px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
