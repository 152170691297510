.containerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -1rem;
}

.calendarWrapper {
    padding-top: 1em;
    padding-bottom: 1em;
}

.total-helper:first-child {
    margin-top: 20px;
}

.calendarWrapper .headerWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 6px 0;
}

.calendarWrapper .headerWrapper .arrowsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.calendarWrapper .headerWrapper .arrowsWrapper span {
    cursor: pointer;
    border-radius: 2rem;
    padding: 2px 10px;
    transition: all ease-in-out 0.1s;
}

.calendarWrapper .headerWrapper .arrowsWrapper span:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.calendarWrapper .weekdaysWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ff7b66;
    border-radius: 4px;
    padding: 0 3rem;
}

.calendarWrapper .weekdaysWrapper .weekDay {
    font-size: 1.4rem;
}

.calendarWrapper .daysWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.calendarWrapper .daysWrapper .blankDay {
    width: calc(100% / 7);
}

.calendarWrapper .daysWrapper .day {
    width: calc(100% / 7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    padding: 1rem 0;
    transition: all ease-in-out 0.1s;
}

.calendarWrapper .daysWrapper .day:hover {
    background-color: #ff7b66;
    color: #fff;
}

.calendarWrapper .daysWrapper .day.selected {
    background-color: #ff7b66;
    color: #fff;
}

.calendarWrapper .daysWrapper .day.active {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
}

.calendarWrapper .daysWrapper .day div {
    display: flex;
    flex-direction: column;
}

.calendarWrapper .daysWrapper .day div span {
    font-size: 0.8rem;
}

.detailsButton {
    margin-left: 0.5rem;
    border: none;
    background-color: #fff;
    border-radius: 4px;
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    transition: all ease-in-out 0.1s;
}

.detailsButton:hover {
    background-color: #ff7b66;
    color: #fff;
}