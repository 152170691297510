.EditableField {

}

.EditableField-detail,
.EditableField-buttonsWrapper,
.EditableField-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.EditableField-row {
  min-height: 40px;
}

.EditableField-detail,
.EditableField-buttonsWrapper {
  align-items: center;
}

.EditableField-row:not(:first-child) {
  margin: 5px 0 0 0;
}

.EditableField-title {
  font-size: 16px;
  flex-basis: 25%;
}

.EditableField-detail {
  font-size: 14px;
  flex-basis: calc(75% - 15px);
}

.EditableField-infoWrapper {
  margin: 0 7px 0 0;
}

.EditableField-infoInput input {
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #888;
}

.EditableField-infoInput,
.EditableField-controls {
  display: none;
}

.EditableField-btn {
  cursor: pointer;
  transition: opacity 300ms ease-out;
  opacity: 1;
}

.EditableField-btn {
  height: 18px;
  font-size: 12px;
}

.EditableField-btn:not(:first-child) {
  margin: 0 0 0 15px;
}

.EditableField-btn:hover {
  opacity: 0.75;
}

.EditableField-btn:active {
  opacity: 0.5;
}

.EditableField--editing .EditableField-infoDisplay,
.EditableField--editing .EditableField-btn--edit {
  display: none;
}

.EditableField--editing .EditableField-infoInput,
.EditableField--editing .EditableField-controls {
  display: block;
}

.EditableField-btn--edit {
  margin: 3px 0 0 0;
}

.EditableField-btn--save,
.EditableField-btn--edit {
  color: #FF7A66;
}

.EditableField-btn--cancel {
  color: #999;
}
