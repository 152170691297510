.ArtistActions-btn:first-child {
  margin: 0 5px 0 0;
}

.ArtistActions-btnWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.ArtistActions-btnWrapper:not(:first-child) {
  margin: 5px 0 0 0;
}

.ArtistActions-btn .ss-icon {
  position: relative;
  top: 2px;
}