.gamification_container {
  margin: 0 !important;
  padding: 0 !important;
  color: #5c5c5c !important;
}

.gamification_title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.gamification_title span {
  display: block;
  margin-top: 0.25rem;
  color: #727272;
  font-size: 1rem;
}

.gamification_current_container {
  display: flex;
  flex-direction: column;
}

.gamification_current_metrics {
  display: grid;
  grid-template-columns: repeat(8, 65px);
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.gamification_current_metric {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.gamification_current_metric > div {
  height: 30px;
}

.gamification_current_metric p {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
}

.gamification_current_metric p.metric-bigger-1000 {
  font-size: 1.5rem;
  margin-top: 5px;
}

.gamification_current_metric img {
  height: 100%;
  width: auto;
  margin-top: 5px;
}

.gamification_current_metric span {
  margin-top: 0.4rem;
  font-weight: bold;
}

.gamification_goals {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  column-gap: 0.75rem;
  row-gap: 0.75rem;
}

.gamification_goal {
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 0.5rem;
}

.gamification_goal_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gamification_goal_title h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
}

.gamification_goal_title h4 {
  margin: 0;
  font-size: 1rem;
  color: #888888;
}

.gamification_goal_current {
  margin: 0;
  margin-left: auto;
  font-size: 2rem;
  font-weight: bold;
}


.gamification_goal_metrics {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.gamification_goal_metric {
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.1rem 0.25rem;
  border-radius: 5px;
  margin-right: 0.25rem;
}
