.ScheduleDetails-history {
  position: relative;
  background: white;
  padding: 25px 25px 30px 25px;
  border-radius: 7px;
  color: #565656 !important;
  margin-bottom: 20px;
}

.ScheduleDetails-detail {
  margin-bottom: 20px;
}

.ScheduleDetails-btn {
  cursor: pointer;
  transition: opacity 300ms ease-out;
  opacity: 1;
}

.Schedule-confirm-email {
  float: right;
  margin-top: -18px;
}

.ScheduleDetails-btn {
  height: 18px;
}

.ScheduleDetails-btn:not(:first-child) {
  margin: 0 0 0 15px;
}

.ScheduleDetails-btn:hover {
  opacity: 0.75;
}

.ScheduleDetails-btn:active {
  opacity: 0.5;
}

.pix-wrapper {
  margin-top: 5px;
}

.pix-payment-title {
  color: #000;
  text-align: center;

  font-family: "OfeliaDisplay";
  font-weight: 400;
  font-size: 16px;
  font-style: normal; /* 20px */
}

.pix-container {
  margin-left: 12px;
  display: flex;
  width: 766px;
  padding: 1px 10px;
  align-items: center;
  gap: 10px;
  border-radius: var(--border-radius-xs, 4px);
  border: 1px solid var(--grey-grey-light, #d1d1d1);
  background: var(--neutral-neutral-white, #fff);
}

.pix-container > span {
  width: 96%;
  overflow: hidden;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;

  font-family: "OfeliaDisplay";
  font-weight: 600;
  line-height: 2.4rem;
  color: #212529;
  font-size: 14px;
  font-style: normal;
}

.pix-container > div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-info-contaier {
  display: flex;
  flex-direction: row;
  align-items: center;
}
