@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@keyframes spinAndGrow {
    0% {
      opacity: 0.6;
      transform: scale(1) rotate(0deg);
    }

    50% {
      opacity: 1;
      transform: scale(0.4) rotate(360deg);
    }

    100% {
      opacity: 0.6;
      transform: scale(1) rotate(0deg);
    }
}
