@import url('./animations.css');

.Form {
  display: block;
}

.Form.is-submitting {
  cursor: wait;
  opacity: 0.5;
}

.Form-formGroup {
  padding: 21px 26px 30px 26px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 7px;
  margin-top: 30px;
}

.Form-formGroup--invisible {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
  transition: opacity 200ms ease-out-cubic,
              transform 400ms ease-out-cubic;
}

.Form-fieldSpinner {
  position: relative;
  top: 2px;
  display: inline-block;
  padding: 5px 0 0 0;
  margin: 0 0 0 10px;
  opacity: 0;
  color: #3B8200;
  animation: spin 1000ms linear infinite;
  transition: opacity 200ms ease-in-out;
}

.Form-fieldSpinner.is-visible {
  opacity: 1;
}

.Form-formGroup.is-visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.Form-formGroup--transparent {
  padding: 0;
  background-color: transparent;
}

.Form .row:not(:last-child) {
  margin-bottom: 30px !important;
}

.Form-labelWrapper,
.Form-inputWrapper {
  position: relative;
  margin: 0;
}

.Form-inputWrapper,
.Form-input {
  width: 100%;
}

.Form-labelWrapper {
  margin-bottom: 13px;
  margin-bottom: 13px;
  min-height: 33px;
}

.Form-label {
  font-weight: normal;
  margin: 0;
  max-width: auto;
  opacity: 0.85;
  transition: opacity 300ms ease-out-cubic,
              color 300ms ease-out-cubic;
}

.Form-label::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  background-color: #ccc;
  height: 1px;
  width: 100%;
}

.Form-input {
  border-radius: 7px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 5px 9px;
  background-color: transparent;
  transition: border-color 300ms ease-out-cubic,
              background-color 300ms ease-out-cubic;
}

.Form-input.input-date .form-control {
  border: none;
  background: transparent;
  padding: 0;
  height: auto;
  box-shadow: none;
}

.Form-input:focus {
  outline: 0;
}

.Form-input::-webkit-input-placeholder {
   opacity: 0.55;
   color: black;
   transition: color 300ms ease-out-cubic,
               opacity 300ms ease-out-cubic;
}

.Form-input:-moz-placeholder {
   opacity: 0.55;
   color: black;
   transition: color 300ms ease-out-cubic,
               opacity 300ms ease-out-cubic;
}

.Form-input::-moz-placeholder {
   opacity: 0.55;
   color: black;
   transition: color 300ms ease-out-cubic,
               opacity 300ms ease-out-cubic;
}

.Form-input:-ms-input-placeholder {
   opacity: 0.55;
   color: black;
   transition: color 300ms ease-out-cubic,
               opacity 300ms ease-out-cubic;
}

.Form-input--textarea {
  height: 130px;
  resize: none;
}

.Form-choiceGroup {
  margin: 7px 0 0 0;
}

.Form-radio {
  display: none;
}

.Form-checkbox {
  position: relative;
  display: inline-block;
  top: -1px;
  margin: 0 6px 0 0 !important;
  cursor: pointer;
  display: none;
}

.Form-fakeCheckbox {
  position: relative;
  top: 0;
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  margin: 0 8px 0 0;
  border-radius: 3px;
  font-size: 9px;
  color: white;
}

.Form-fakeCheckbox .ss-check {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  top: 2px;
  left: 2px;
  position: absolute;
}

.Form-choice {
  display: inline-block;
  margin: 0 7px 7px 0;
}

.Form-uploadField,
.Form-choiceLabel {
  position: relative;
  cursor: pointer;
  border-radius: 7px;
  padding: 9px 15px 8px 14px;
  border: 1px solid #aaa;
  font-size: 12.5px;
  font-weight: 300;
  text-transform: uppercase;
  background-color: rgb(162, 162, 162);
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 350ms ease-out-cubic,
              color 350ms ease-out-cubic,
              opacity 250ms ease-out-cubic;
}

.Form-choiceLabel,
.Form-uploadLabel,
.Form-uploadProgress,
.Form-uploadFace {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Form-uploadFace {
  position: absolute;
  padding: 0 15px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.Form-uploadIcon {
  padding: 2px 0 0 0;
  margin: 0 0 0 5px;
}

.Form-uploadField {
  width: 205px;
  height: 40px;
  overflow: hidden;
  margin: 0 auto;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

.Form-uploadProgress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
  background-color: #848484;
  z-index: 10;
  transition: width 150ms ease;
}

.Form-uploadingText {
  position: relative;
  z-index: 100;
}

.Form-uploadedText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.Form-uploadField input[type=file] {
  display: none;
}

.Form-uploadLabel {
  height: 100%;
  width: 100%;
  cursor: pointer;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.Form-uploadPreview {
  position: relative;
  width: 130px;
  height: 130px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #ccc;
  color: #fff;
  margin: 0 auto 15px auto;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.Form-uploadPreviewEmptyIcon,
.Form-uploadPreviewChangeContent,
.Form-uploadPreviewChangeIcon,
.Form-uploadPreviewChangeIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Form-uploadPreview--circle {
  border-radius: 100%;
}

.Form-uploadPreview--circle .Form-uploadPreviewChangeIndicator {
  top: 20px;
  right: 20px;
}

.Form-uploadPreviewImage {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.Form-uploadPreviewEmptyIcon {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 14px 0 0 0;
  opacity: 1;
  font-size: 50px;
  z-index: 10;
  cursor: pointer;
}

.Form-uploadPreviewImage,
.Form-uploadPreviewEmptyIcon {
  position: absolute;
  transition: opacity 400ms ease-in-out-quad;
}

.Form-uploadPreviewChangeContent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.Form-uploadPreviewChangeIcon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid white;
  padding-top: 3px;
  font-size: 15px;
}

.Form-uploadPreviewChangeIndicator {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid white;
  padding-top: 2px;
  font-size: 9.5px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.75);
}

.Form-uploadPreviewChangeContent,
.Form-uploadPreviewChangeIndicator {
  transition: opacity 300ms ease-out-cubic;
}

.Form-uploadPreview.is-uploaded .Form-uploadPreviewEmptyIcon {
  display: none;
}

.Form-uploadPreview.is-uploaded .Form-uploadPreviewImage {
  opacity: 1;
}

.Form-uploadPreview:hover .Form-uploadPreviewChangeContent {
  opacity: 1
}

.Form-uploadPreview:hover .Form-uploadPreviewChangeIndicator {
  opacity: 0;
}

.Form-uploadModalImage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Form-uploadModalImage--avatar img {
  border-radius: 100%;
}

.Form-uploadModalImage img {
  max-width: 100%;
}

.Form-uploadCropAvatar {
  text-align: center;
  padding: 50px;
}

.Form-uploadCropAvatar canvas {
  border-radius: 100%;
}

.Form-uploadCropBtn {

}

.Form-choiceLabel--radio {
  font-style: italic;
  background-color: #818181;
}

.Form-choice:first-child {
  margin-left: 0;
}

.Form-choice.is-selected .Form-choiceLabel {
  background-color: #FF7A66;
  border-color: transparent;
  color: white;
}

.Form-choiceGroup.is-selected .Form-choice:not(.is-selected) .Form-choiceLabel {
  opacity: 0.4;
}

.Form-choiceGroup.is-selected .Form-choice:not(.is-selected):hover .Form-choiceLabel {
  opacity: 0.85;
}

.Form-choice.is-selected .ss-check {
  opacity: 1;
}

.Form-accordion {
  position: relative;
  background-color: #ddd;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 7px;
}

.Form-accordion .ss-plus {
  position: relative;
  display: inline-block;
  top: 1px;
  font-size: 12px;
  color: #FF7A66;
  margin: 0 10px 0 0;
  transition: transform 300ms ease-out-cubic,
              color 300ms ease-out-cubic;
}

.Form-accordion:not(:last-child) {
  margin: 0 0 12px 0;
}

.Form-accordionTitle {
  cursor: pointer;
  text-transform: lowercase;
  font-style: italic;
  color: #616161;
  padding: 10px 15px;
  transition: color 300ms ease-out-cubic;
}

.Form-accordionTitle:hover {
  color: black;
}

.Form-accordionContent {
  overflow: hidden;
  opacity: 0;
  padding: 0 15px;
  transition: height 400ms ease-in-out,
              opacity 300ms ease-out-cubic;
}

.Form-autoComplete {
  position: absolute;
  left: 0;
  top: 79px;
  z-index: 500;
  max-height: 220px;
  height: 0;
  background-color: #222;
  width: 100%;
  opacity: 0;
  overflow: auto;
  border-radius: 7px;
  visibility: hidden;
  transition: opacity 265ms ease-in-out;
}

.Form-autoCompleteList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Form-autoComplete.is-opened {
  opacity: 1;
  visibility: visible;
}

.Form-autoCompleteItem {
  cursor: pointer;
  color: #cdcdcd;
  transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  font-size: 16px;
}

.Form-autoCompleteItem:hover {
  color: white;
}

.Form-autoCompleteItem:hover .Form-autoCompleteItemContent {
  background-color: rgba(255, 255, 255, 0.06);
}

.Form-autoCompleteItemContent {
  padding: 7px 10px 9px 10px;
  background-color: rgba(255, 255, 255, 0.12);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: background-color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.Form-autoCompleteItem .ss-location {
  position: relative;
  top: 1px;
  font-size: 12.5px;
  margin: 0 6px 0 0;
  opacity: 0.3;
}

.Form-autoCompleteItem:not(:last-child) {
  border-bottom: 2px solid transparent;
}

.Form-accordion .Form-inputWrapper {
  padding-bottom: 15px;
}

.Form-uploadField.is-uploading .Form-uploadFace {
  cursor: wait;
}

.Form-uploadField:not(.is-uploading):hover {
  background-color: #848484;
}

.Form-choice:not(.is-selected):hover .Form-choiceLabel {
  background-color: #848484;
}

.Form-choice:not(.is-selected):hover .Form-choiceLabel--radio {
  background-color: #707070;
}

.Form-accordion.is-opened .Form-accordionContent {
  opacity: 1;
}

.Form-accordion.is-opened .ss-plus {
  transform: rotate(45deg);
  color: #999;
}

.Form-error {
  padding: 0 0 0 7px;
  font-style: italic;
  color: #FF6F6A;
  font-size: 13px;
}

.Form-submitWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.Form-submitError {
  text-align: center;
  color: #FF6F6A;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.Form-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: white;
  font-weight: bolder;
  font-size: 16px;
  padding: 20px 40px;
  background-color: #FF7A66;
  border: 0;
  border-radius: 7px;
  transition: background-color 300ms ease-out-cubic,
              opacity 300ms ease-out-cubic;
}

.Form-submit[disabled] {
  opacity: 0.5;
}

.Form-submit:focus {
  outline: none;
}

.Form-submit .ss-icon {
  position: relative;
  top: 2px;
  margin: 0 0 0 10px;
}

.Form-submit:hover {
  background-color: #FF7A66;
}

.Form-inputWrapper.is-focused .Form-label {
  opacity: 1;
}

.Form-inputWrapper.is-focused .Form-input {
  background-color: rgb(255, 255, 255);
  border-color: rgba(0, 0, 0, 0.4);
  outline: none;
}

.Form-inputWrapper.is-focused .Form-input::-webkit-input-placeholder {
   opacity: 0.3;
}

.Form-inputWrapper.is-focused .Form-input:-moz-placeholder {
   opacity: 0.3;
}

.Form-inputWrapper.is-focused .Form-input::-moz-placeholder {
   opacity: 0.3;
}

.Form-inputWrapper.is-focused .Form-input:-ms-input-placeholder {
   opacity: 0.3;
}

.Form-inputWrapper.is-error {
  color: #FF6F6A;
}

.Form-inputWrapper.is-error .Form-input {
  border-color: #FF6F6A;
}

.Form-inputWrapper.is-error .Form-input::-webkit-input-placeholder {
   color: #FF6F6A;
}

.Form-inputWrapper.is-error .Form-input:-moz-placeholder {
   color: #FF6F6A;
}

.Form-inputWrapper.is-error .Form-input::-moz-placeholder {
   color: #FF6F6A;
}

.Form-inputWrapper.is-error .Form-input:-ms-input-placeholder {
   color: #FF6F6A;
}

.Form-inputWrapper.is-valid {
  color: #333;
}

.Form-inputWrapper.is-valid .Form-input {
  border-color: #7ab648;
}

.Form-inputWrapper.is-valid .Form-input::-webkit-input-placeholder {
   color: #3B8200;
}

.Form-inputWrapper.is-valid .Form-input:-moz-placeholder {
   color: #3B8200;
}

.Form-inputWrapper.is-valid .Form-input::-moz-placeholder {
   color: #3B8200;
}

.Form-inputWrapper.is-valid .Form-input:-ms-input-placeholder {
   color: #3B8200;
}

.Form-inputWrapper.is-disabled {
  opacity: 0.2;
}

.Form-inputWrapper.is-disabled * {
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  color: #fff;
  animation: spin 1000ms linear infinite;
  transition: opacity 200ms ease-in-out;
}

.no-selection {
  color: rgba(0, 0, 0, 0.5)
}

@media (max-width: 768px) {
  .Form-inputWrapper {
    margin-bottom: 15px;
  }
}
