.switch-checkbox {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  cursor: pointer;
  margin: auto;
}

.switch-inner {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.switch-checkbox.checked {
  background-color: #FF7A66;
}

.switch-checkbox.checked .switch-inner {
  transform: translateX(20px);
}