.UserInfo {

}

.UserInfo-section {
  position: relative;
}

.UserInfo-section:not(:first-child) {
  margin-top: 30px;
}

.UserInfo-subSection:not(:first-child) {
  margin-top: 10px;
}

.UserInfo-title {
  font-size: 20px;
  font-weight: 800;
  margin: 0 0 20px 0;
}

.UserInfo-subSectionTitle,
.UserInfo .ReadOnlyField-title,
.UserInfo .EditableField-title {
  font-size: 15px;
  font-weight: 500;
}

.UserInfo-subSectionInfo,
.UserInfo .ReadOnlyField-info,
.UserInfo .EditableField-detail {
  font-size: 14px;
}

.UserInfo-actionBtn:not(:first-child) {
  margin: 0 0 0 10px;
}

.UserInfo-removeablesWrapper {
  display: flex;
  flex-flow: row wrap;
}

.UserInfo-removeablesWrapper .RemoveableField:not(:last-child) {
  margin-right: 6px;
}

.UserInfo-removeablesWrapper .RemoveableField {
  margin-bottom: 6px;
}

.Form-input{
  resize: none;
}

.Form-select {
  width: 100%;
}


.is-invalid{
  border-color: rgba(255, 0, 0, .2)!important;
}
.invalid-feedback{
  color: rgba(255, 0, 0, .5);
}
.UserInfo-container{
  display: flex;
  align-items: center;
  justify-content: center
}
.UserInfo-container button{
  margin-left: 15px;
}
.UserInfo-container p{
  margin-bottom: 0;
}