.areaInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.infoDetails {
    background-color: rgba(50, 50, 50, 0.2);
    padding: 1rem;
    margin: 1rem;
    border-radius: 0.4rem;
}

.modalAccordion {
    margin: 1rem 1rem;
}

.title {
    font-size: 1rem;
    font-weight: 600;
}

.desc {
    font-size: 1rem;
    font-weight: 300;
}

.category {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 50%;
    float: left;
    padding: 1em;
}

.category:nth-child(even) {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}