progress {
  border-radius: 7px; 
  width: 100%;
  height: 30px;
}
progress::-webkit-progress-bar {
  background-color: #dadada;
  border-radius: 7px;

}
progress::-webkit-progress-value {
  background-color: #FF7A66;
  border-radius: 7px;
}

progress::-moz-progress-bar {
  /* style rules */
}

.progressbar_container {
  width: 100%;
}

/* Days */
.progressbar_steps {
  display: flex;
  justify-content: space-between;
}

.progressbar_steps div {
  font-weight: bold;
  font-size: 1rem;
  margin-top: -5px;
  color: #727272;
}