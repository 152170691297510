.infos-container{
    background: #fff;
    padding: 48px 60px;
    border-radius: 6px;
    color: #333;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.2);
}
.section-title{
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}
.infos-row h3{
    font-size: 18px;
}
.infos-row{
    border-bottom: 1px solid #ccc;
}