.Schedules-redRow .Schedules-status {
  color: #CA5E5C !important;
}

.Schedules-redRow td:first-child::before {
  background-color: #CA5E5C !important;
}

.Schedules-greenRow .Schedules-status {
  color: #6cb2ff !important;
}

.Schedules-greenRow td:first-child::before {
  background-color: #6cb2ff !important;
}

.Schedules-yellowRow .Schedules-status {
  color: #FF9439 !important;
}

.Schedules-yellowRow td:first-child::before {
  background-color: #FF9439 !important;
}

.Schedules-row td:first-child {
  position: relative;
}

.Schedules-row:hover td,
.Schedules-row:hover .Schedules-status,
.Schedules-row:hover a {
  transition: color 300ms ease-out;
}

.Schedules-row td:first-child::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
  z-index: 1;
}

.Schedules-redRow:hover {
  background-color: (#d16f6d, 4, 0) !important;
}

.Schedules-yellowRow:hover {
  background-color: #FF9439 !important;
}

.Schedules-greenRow:hover {
  background-color: #6cb2ff !important;
}

.Schedules-row:hover td,
.Schedules-row:hover .Schedules-status,
.Schedules-row:hover a {
  color: black !important;
}

.Schedules-btn-export {
    margin-bottom: 10px !important;
}

.Filter {
  margin-top: 15px;
  margin-bottom: 25px;
}