@import url('./../../../style/variables.css');

.Navbar {
  align-content: center;
  height: var(--nav-height);
  background-image: linear-gradient(-135deg, #FF9C66 0%, #FF7A66 50%, #FF667A 100%);
}

.Navbar.development {
  background-image: linear-gradient(-135deg, #FF9C66 0%, #FF7A66 50%, #FF667A 100%);
}

.Navbar .container,
.Navbar-linksWrapper {
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
}

.Navbar .container {
  position: relative;
  height: 100%;
  padding: 0;
}

.Navbar-link a,
.Navbar-logo {
  opacity: 1;
  transition: opacity 300ms ease-out-cubic;
}

.Navbar-link a:hover,
.Navbar-logo:hover {
  opacity: 0.75;
}

.Navbar-link a:active,
.Navbar-logo:active {
  opacity: 0.5;
}

.Navbar-linksWrapper {
  padding: 0 0px;
  margin: 0;
  height: 100%;
}

.Navbar-link,
.Navbar-link a {
  color: #fff;
  text-decoration: none;
  list-style: none;
}

.Navbar-link {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: column;
  height: 100%;
  padding: 0 8px;
  z-index: 1;
  transition: background-color 500ms ease-out-circ;
}

.Navbar-submenu {
  position: absolute;
  top: 100%;
  left: 50%;
  background-image: linear-gradient(-135deg, #FF9C66 0%, #FF7A66 50%, #FF667A 100%);
  opacity: 0;
  padding: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0;
  z-index: 900;
  overflow: hidden;
  transform: translate3d(-50%, 0, 0);
  transition: height 500ms ease-out-circ,
              opacity 500ms ease-out-circ;
}

/*.Navbar-link::after {
  content: '';
  position: absolute;
  bottom: 14px;
  left: 50%;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #fff;
  opacity: 0;
  transition: opacity 500ms ease-out-circ;
}

.Navbar-link.is-opened::after {
  opacity: 1
}*/

.Navbar-submenu ul {
  list-style: none;
  padding: 0;
}

.Navbar.development .Navbar-submenu {
  background-image: linear-gradient(-135deg, #FF9C66 0%, #FF7A66 50%, #FF667A 100%);
}

.Navbar-link--hasSubmenu:hover .Navbar-submenu {
  opacity: 1;
}

.Navbar-link.is-closed .Navbar-submenu {
  height: 0 !important;
}

.Navbar-submenuLink {
  line-height: 1.2;
  white-space: nowrap;
  padding: 12px 20px 12px 18px;
  border-bottom: 1px solid rgba(#fff, 0.25);
  font-size: 14px;
}

.Navbar-submenuIcon {
  font-size: 10px;
  margin: 0 5px 0 0;
}

.Navbar-link:not(:first-child) {
  margin: 0 0 0 12px;
}

.Navbar-icon {
  position: relative;
  top: 2px;
  margin: 0 7px 0 0;
}

.Navbar-appVersion {
  position: fixed;
  bottom: 5px;
  left: 5px;
  color: #232323;
  padding: 5px 5px;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  width: 40px;
  height: 40px;
  z-index: 900;
  transform: translate3d(0, 0, 0) scale(0.7);
  transition: transform 250ms ease-out-cubic;
}

.Navbar-appVersion:hover {
  transform: translate3d(0, 0, 0) scale(1);
}
