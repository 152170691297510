.ChangeService {
}

.ChangeService-row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.ChangeService-row:not(:first-child) {
  margin: 10px 0 0 0;
}

.ChangeService-title {
  font-size: 16px;
  margin: 0 0 0 15px;
}

.ChangeService-btn:not(:first-child) {
  margin: 0 0 0 20px;
}

.ChangeService-select {
  flex: 1;
}
