.addComment {
}

#comment {
  height: 100px;
  margin-bottom: 10px;
}

.addComment-title {
  font-size: 16px;
  margin: 0 0 10px 0;
}

.addComment-row {
  display: flex;
  flex-flow: row wrap;
}

.addComment-btn {
  flex-basis: calc(10% - 20px);
  margin: 0 0 0 82%;
}

.addComment-modal {
  margin-top: 30%;
}