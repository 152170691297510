/* Actions */
.TransfersActions-btn:first-child {
  margin: 0 5px 0 0;
}

.TransfersActions-btnWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.TransfersActions-btnWrapper:not(:first-child) {
  margin: 5px 0 0 0;
}

.TransfersActions-btn .ss-icon {
  position: relative;
  top: 2px;
}

/* Griddle row */
.Transfers-dangerRow .Transfers-status {
  color: #d9534f !important;
}

.Transfers-dangerRow td:first-child::before {
  background-color: #d9534f !important;
}

.Transfers-successRow .Transfers-status {
  color: #5cb85c !important;
}

.Transfers-successRow td:first-child::before {
  background-color: #5cb85c !important;
}

.Transfers-infoRow .Transfers-status {
  color: #5bc0de !important;
}

.Transfers-infoRow td:first-child::before {
  background-color: #5bc0de !important;
}

.Transfers-row td:first-child {
  position: relative;
}

.Transfers-row:hover td,
.Transfers-row:hover .Transfers-status,
.Transfers-row:hover a {
  transition: color 300ms ease-out;
}

.Transfers-row td:first-child::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
  z-index: 1;
}