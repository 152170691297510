:root {
  /* Dimensions */
  --nav-height: 65px;

  /* Colors */
  --primary-color-prod: #483055;
  --primary-color-prod-darker: #382642;
  --primary-color-stage: #652442;
  --primary-color-stage-darker: #441A2D;

  /* Fonts */
  --default-font-family: 'Source Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

}
