.CancelSchedule {

}

.CancelSchedule-row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.CancelSchedule-CommentSelect {
  flex: 1;
}

.CancelSchedule-Comment {
  margin-top: 20px;
}

.CancelSchedule-row:not(:first-child) {
  margin: 10px 0 0 0;
}

.CancelSchedule-title {
  font-size: 16px;
  margin: 0 0 0 15px;
}

.CancelSchedule-btn:not(:first-child) {
  margin: 0 0 0 20px;
}

.CancelSchedule-select {
  flex: 1;
}
