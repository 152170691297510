.content {
  padding-left: 1.0625rem; /* 17px */
  padding-right: 1.0625rem; /* 17px */
}

.payment-method-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  padding-left: 0.75rem; /* 12px */
  padding-right: 0.75rem; /* 12px */
  padding-top: 0.375rem; /* 6px */
  padding-bottom: 0.375rem; /* 6px */

  border-radius: 0.25rem; /* 4px */
  border: 1px solid #CED4DA;

  width: 100%;

  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;

  color: #6C757D;

  option {
    color: #212529;
    font-size: 1rem; /* 16px */
    font-weight: 400;
    line-height: 1.5rem; /* 24px */
  }

}

.content-text {
  font-size: 1.125rem; /* 18px */
  color: #212121;
  line-height: 1.875rem; /* 30px */
  font-weight: 400;
}

.input-copy-pix {
  width: 100%;
  height: 2.625rem; /* 42px */
  padding: 0.75rem; /* 12px */
  color: #000;
  border-radius: 0.25rem; /* 4px */
  border: 1px solid #D1D1D1;
  margin-top: 1rem; /* 16px */
}

.input-container {
  position: relative;
  width: 100%;
}

.input-copy-pix {
  width: 100%;
  height: 2.625rem; /* 42px */
  padding: 0.75rem; /* 12px */
  padding-right: 3.75rem; /* 60px - Deixa espaço para o botão dentro do input */
  color: #000;
  border-radius: 0.25rem; /* 4px */
  border: 1px solid #D1D1D1;
  margin-top: 1rem; /* 16px */
  box-sizing: border-box;
}

.copy-button:hover {
  background-color: #0056b3;
}
