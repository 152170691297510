@import url('./../../../style/animations.css');

.Loading {
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.Loading-spinner {
  display: inline-block;
  padding: 23px 0 0 0;
  font-size: 80px;
  color: #777;
  animation: spinAndGrow 2750ms infinite;
}
