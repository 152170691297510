@font-face {
  font-family: "Source Sans";
  src: url("./fonts/SourceSansPro-Regular.otf");
}

@font-face {
  font-family: "Source Sans";
  src: url("./fonts/SourceSansPro-Semibold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Source Sans";
  src: url("./fonts/SourceSansPro-It.otf");
  font-style: italic;
}

@font-face {
  font-family: "OfeliaDisplay";
  src: url("./fonts/OfeliaDisplay-Regular.otf");
}

@font-face {
  font-family: "OfeliaDisplay";
  src: url("./fonts/OfeliaDisplay-Regular.otf");
  font-weight: 500;
}

@font-face {
  font-family: "OfeliaDisplay";
  src: url("./fonts/OfeliaDisplay-Medium.otf");
  font-weight: 600;
}

@font-face {
  font-family: "OfeliaDisplay";
  src: url("./fonts/OfeliaDisplay-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "OfeliaDisplay";
  src: url("./fonts/OfeliaDisplay-Italic.otf");
  font-style: italic;
}
