.BanPartner {
  text-align: center;
  padding: 15px 0;
}

.BanPartner-message {
  font-size: 18px;
}

.BanPartner-btnWrapper {
  margin: 25px 0 0 0;
}

.BanPartner-btn:not(:first-child) {
  margin: 0 0 0 10px;
}
