.VoucherActions-btn:first-child {
    margin: 0 5px 0 0;
  }

  .VoucherActions-btnWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  .filter-container {
    display: none !important;
  }

  .VoucherActions-btnWrapper:not(:first-child) {
    margin: 5px 0 0 0;
  }

  .VoucherActions-btn .ss-icon {
    position: relative;
    top: 2px;
  }