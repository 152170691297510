.LeadDetails-title {
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 15px 0;
  text-transform: uppercase;
  font-style: italic;
}

.red-row{
  background-color: #e8b2ab !important;
}

.LeadDetails-section {
  background: rgba(0, 0, 0, 0.075);
  padding: 25px 25px 30px 25px;
  border-radius: 7px;
  color: #565656 !important;
}

.LeadDetails-section:not(:last-child) {
  margin-bottom: 30px;
}

.LeadDetails .row:nth-child(n+3) {
  margin-top: 30px;
}

.LeadDetails .EditableField-title,
.LeadDetails-displayLabel {
  text-transform: lowercase;
  font-style: italic;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 0 2px 0;
  margin: 0 0px 4px 0;
}

.LeadDetails-migrateLeadBtnWrapper {
  margin: 10px 0 0 0;
}

.LeadDetails-migrateLeadBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 20px 40px;
  margin: 0 auto;
  background-color: #FF7A66;
  border: 0;
  border-radius: 7px;
  transition: background-color 300ms ease-out-cubic,
              opacity 300ms ease-out-cubic;
}

.LeadDetails-migrateLeadBtn[disabled] {
  opacity: 0.5;
}

.LeadDetails-migrateLeadBtn:focus {
  outline: none;
}

.LeadDetails-migrateLeadBtn .ss-icon {
  position: relative;
  top: 2px;
  margin: 0 0 0 10px;
}

.LeadDetails-migrateLeadBtn:hover {
  background-color: #FF7A66;
}

.lead-status-card {
  margin: 0;
  background-color: #fff;
  padding: .5rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.lead-status-card h4 {
  margin: 0;
  margin-bottom: 0.5rem;
}

.lead-status-card-topic {
  margin-top: 0.25rem;
}

.lead-status-card-topic b,
.lead-status-card-topic p {
  margin: 0;
}

.lead-status-card-topic b {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
}

.lead-status-card-topic p {
  margin-top: -0.2rem;
}
