@import url('./variables.css');
@import url('./fonts.css');

html, body {
  height: 100%;
  width: 100%;
  font-family: var(--default-font-family);
  font-size: 15px;
}

#app {
  height: 100%;
  width: 100%;
}

.form-control {
  width: 200px;
  display: initial !important;
}

.form-control-normal{
  width: 100%;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.form-code {
  width: 100px !important;
  display: initial !important;
  border-radius: 5px;
  border-bottom: 1px solid #eaeaea;
}

.btn-info {
  color: #fff;
  background-color: #FF7A66 !important;
  border: 0 !important;
}

.btn-info:hover {
  filter: brightness(95%);
}

.btn-info:disabled {
  background-color: #afafaf !important;
}

.form-group {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.margin-right-0px {
    margin-right: 0px !important;
}

.no-border {
    border: 'none';
    box-shadow: 'none';
}

.section-title {
  font-size: 32px;
  font-weight: bold;
  border-bottom: 1px solid #eaeaea;
  margin: 0 0 30px 0;
  color: #5f5c5cf2;
}

.main-container {
  padding-top: 30px;
  width: 90%;
}

.button-icon span{
  margin-right: 10px;
}

.pt-1{
  padding-top: 10px;
}

.pt-2{
  padding-top: 15px;
}

button {
  transition: background-color 300ms ease;
}

a {
  color: #FF7A66;
  text-decoration: underline;
}

.griddle-footer div {
  display: flex;
}


.griddle-next,
.griddle-page,
.griddle-previous {
  padding-left: 10px;
  font-size: 20px;
}

.griddle-next button,
.griddle-next select,
.griddle-page button,
.griddle-page select,
.griddle-previous button,
.griddle-page select{
  color: #fff;
  background-color: #FF7A66 !important;
  border: 0 !important;
  display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.griddle-page select option {
  color: black;
}
 