.Modal--large .modal-dialog {
  width: 80%;
}

.Modal .modal-content {
  padding: 0 15px;
  max-height: calc(100vh - 60px);
  overflow: auto;
}

.Modal-title {
  font-size: 22px;
}

.Modal-sectionTitle {
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 15px 0;
  text-transform: uppercase;
  font-style: italic;
}

.Modal-section {
  position: relative;
  background: #f7f7f7;
  padding: 25px 25px 30px 25px;
  border-radius: 7px;
  color: black !important;
}

.Modal-section:not(:last-child) {
  margin-bottom: 30px;
}

.Modal .EditableField-title,
.Modal-displayLabel {
  text-transform: lowercase;
  font-style: italic;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 0 2px 0;
  margin: 0 0px 4px 0;
  color: black;
}

.Modal-row {
  display: flex;
  flex-flow: row wrap;
}

.Modal-row:not(:first-child) {
  margin: 10px 0 0 0;
}

.Modal-subTitle {
  font-size: 14px;
  margin: 0 0 10px 0;
}

.Modal-separator {
  position: absolute;
  height: 1px;
  background-color: #ccc;
  width: 94%;
  left: 3%;
  bottom: -1px;
}

.Modal-Reactivation .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media (min-width: 1266px) {
    width: 1266px !important;
  }
}

.Modal-Reactivation .modal-content {
  @media (min-width: 1266px) {
    width: 1266px !important;
  }
}
