.GriddleTable {
  border: 1px solid #ddd;
}

.GriddleTable th,
.GriddleTable thead + tbody tr {
  transition: background-color 225ms ease;
}

.GriddleTable thead tr {
  text-transform: uppercase;
}

.GriddleTable thead th,
.GriddleTable tbody td {
  /* border: 1px solid #bababa !important; */
  vertical-align: middle !important;
}

.GriddleTable thead + tbody tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.GriddleTable thead + tbody tr:nth-child(even) {
  background-color: #fff;
}

.GriddleTable thead + tbody tr:hover {
  background-color: #ddd;
}

.GriddleTable tbody+tbody {
  border: 0;
}

.GriddleTable tbody+tbody tr {
  background-color: #fff !important;
}

.GriddleTable .paginate-info {
  display: none;
}

.GriddleTable .paginate {
  margin: 8px 0 0 25px;
}
.griddle .griddle-settings-toggle,
.griddle .griddle-container {
  margin: 15px 0 0 0;
}
