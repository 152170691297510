.UpdateTakeRate {
}

.UpdateTakeRate-title {
  font-size: 16px;
  margin: 0 0 10px 0;
}

.UpdateTakeRate-row {
  display: flex;
  flex-flow: row wrap;
}

.UpdateTakeRate-datepicker {
  flex-basis: 70%;
}

.UpdateTakeRate-btn {
  flex-basis: calc(30% - 20px);
  margin: 0 0 0 20px;
}
