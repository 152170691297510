.btn-pack {
  margin: 5px 10px;
}

.panel-heading {
  float: left;
  width: 100%;
}

.pack-total {
  border-bottom: 1px solid #00000029;
  padding: 10px 0px;
}

.pack-title {
  margin: 0;
}

.btn-area .active {
  cursor: pointer;
  color: #ff7a66 !important;
}

.btn-edit-pack {
  background: none;
  text-align: right;
  float: right;
  border: none;
}

.panel-body {
  height: 560px;
  overflow-y: auto;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px !important;
  flex-wrap: wrap;
  max-width: 750px;
  margin: 0 auto;
  gap: 10px;
}