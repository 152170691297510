.container-schedule-artist {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
}

.form-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.day-title {
  width: 100px;
  margin: auto;
  cursor: default;
}

.Form-scheduleTitle {
  font-style: italic;
  color: #616161;
  padding: 10px 0px;
  transition: color 300ms ease-out-cubic;
}

.Form-scheduleTitle:hover {
  color: black;
}

.scheduling-block {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-block: 10px;
  flex-direction: column;
}

.scheduling-time-block {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}

.AddField-btn {
  cursor: pointer;
  transition: opacity 300ms ease-out;
  opacity: 1;
}

.AddField-btn {
  height: 15px;
  font-size: 16px;
  margin: 3px 0 0 0;
}

.AddField-btn:hover {
  opacity: 0.75;
}

.AddField-btn:active {
  opacity: 0.5;
}


.AddField-btn--add {
  color: #5cb85c ;
}

.AddField-btn--remove {
  color: #d9534f;
}