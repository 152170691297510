h3, h4, h5 {
  margin: 0;
}

.report-macro-data {
  margin-top: 0.5rem;
}

.report-macro-data h3,
.report-macro-data h4 {
  font-weight: bold;
}

.report-micro-data h5 {
  margin-left: 0.25rem;
}

hr {
  margin: 0.2rem 0;
}


