.Login {
    position:fixed;
    text-align: center;
    top: 50%;
    left: 50%;
    width:30em;
    height:18em;
    margin-top: -9em; /*set to a negative number 1/2 of your height*/
    margin-left: -15em; /*set to a negative number 1/2 of your width*/
    border: 1px solid #ccc;
    background: white;
    border-radius: 30px 30px 30px 30px;
}

.LoginBody {
    width: 100%;
    height: 100%;
    background-image: url('https://singu.com.br/images/fundo-home-2.png'), -webkit-linear-gradient(0deg, #ff813f, #ff573e 50%, #ff3e58);
    background-image: url('https://singu.com.br/images/fundo-home-2.png'), linear-gradient(90deg, #ff813f, #ff573e 50%, #ff3e58);
    background-position: 0px 0px, 0px 0px;
    background-size: cover, auto;
    background-attachment: fixed, scroll;
}

.Login-btn {
    position: relative;
    float: right;
    background: #38acff;
    color: white;
}