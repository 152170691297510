.Realtime-redRow .Realtime-status {
    color: #CA5E5C !important;
  }
  
  .Realtime-redRow td:first-child::before {
    background-color: #CA5E5C !important;
  }
  
  .Realtime-greenRow .Realtime-status {
    color: #6cb2ff !important;
  }
  
  .Realtime-greenRow td:first-child::before {
    background-color: #6cb2ff !important;
  }
  
  .Realtime-yellowRow .Realtime-status {
    color: #FF9439 !important;
  }
  
  .Realtime-yellowRow td:first-child::before {
    background-color: #FF9439 !important;
  }
  
  .Realtime-row td:first-child {
    position: relative;
  }
  
  .Realtime-row:hover td,
  .Realtime-row:hover .Realtime-status,
  .Realtime-row:hover a {
    transition: color 300ms ease-out;
  }
  
  .Realtime-row td:first-child::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    z-index: 1;
  }
  
  .Realtime-redRow:hover {
    background-color: #d16f6d !important;
  }
  
  .Realtime-yellowRow:hover {
    background-color: #FF9439 !important;
  }
  
  .Realtime-greenRow:hover {
    background-color: #6cb2ff !important;
  }
  
  .Realtime-row:hover td,
  .Realtime-row:hover .Realtime-status,
  .Realtime-row:hover a {
    color: black !important;
  }
  
  .Realtime-btn-export {
      margin-bottom: 10px !important;
  }
  
  .Filter {
    margin-top: 15px;
    margin-bottom: 25px;
  }