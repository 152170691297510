.takeRate-box {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 6px;
  width: 31%;
}
.container-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.page-numbers {
  padding: 0;
}
