.btn-campaign {
  margin: 5px 10px;
}

.panel-heading {
  float: left;
  width: 100%;
}

.campaign-title {
  margin: 0;
}

.btn-add {
  position: absolute;
  right: 0;
}

.btn-area .active {
  cursor: pointer;
  color: #ff7a66 !important;
}

.btn-edit-campaign {
  background: none;
  text-align: right;
  float: right;
  border: none;
}

.panel-body {
  height: 460px;
  overflow-y: auto;
}