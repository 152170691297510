.RemoveableField {
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #888;
  background-color: #fff;
  padding: 5px 8px;
  transition: background-color 300ms ease,
              border-color 300ms ease;
}

.RemoveableField-icon {
  position: relative;
  top: 2px;
  display: inline-block;
  color: #888;
  transition: color 300ms ease;
  margin: 0 0 0 5px;
}

.RemoveableField:hover {
  border-color: #555;
  background-color: #e1e1e1;
}

.RemoveableField:hover .RemoveableField-icon {
  color: #555;
}

.RemoveableField-btn:not(:first-child) {
  margin-left: 5px;
}
